'use client'

import mixpanel from 'mixpanel-browser';
import { IUserInfo } from '@/config/store/globalStore';

type actionType = 'Clicked' | 'Scrolled' | 'Viewed'

export const mixpanelTrack = (obj: string, action: actionType, properties = {}) => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    mixpanel.track(`${obj}_${action}`, properties);
  } else {
    console.log(`LOG: ${obj}_${action}`, properties);
  }
};

export const mixpanelTrackPageView = (page: string, properties = {}) => {
  // if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
  //   mixpanel.track(`${page}_Viewed`, properties);
  // } else {
  //   console.log(`LOG: ${page}_Viewed`, properties);
  // }
};

export const mixpanelSetUser = (userInfo: IUserInfo) => {
  // mixpanel.identify(userInfo?.user_id);
  // mixpanel.people.set({
  //   $userId: userInfo.user_id,
  //   $tier: userInfo.tier,
  //   $placeId: userInfo.placeId,
  //   $placeType: userInfo.placeType,
  //   $placeName: userInfo.placeName,
  // });
};
