import { FAQS } from '@/consts/faqs';
import { Container, Accordion } from '@mantine/core';

export default function Faq() {
  return (
    <Container id={'faq'} className='pt-[100px] w-full sm:pt-[120px] pb-[40px]'>
      <div className='text-center uppercase font-semibold text-base text-gray-500'>자주 물어보는 질문</div>
      <div className='text-center text-2xl md:text-3xl font-bold text-gray-700 mb-10 mt-2'>FAQ</div>
      <div className='p-4' data-aos="fade-up" data-aos-delay="250" data-aos-duration="300">
        <Accordion variant={"separated"}>
          { FAQS.map((item, index) => (
            <Accordion.Item key={index} className='rounded-lg mb-4 border-1 border-gray-200' value={item.question}>
              <Accordion.Control classNames={{ label: 'text-sm md:text-base' }}>{item.question}</Accordion.Control>
              <Accordion.Panel className='text-sm md:text-base leading-6'>{item.answer.split('\n').map(i => <>{i}<br/></>)}</Accordion.Panel>
            </Accordion.Item>
          ))}
          </Accordion>
      </div>
    </Container>
  );
}