import { FeatureType } from '@/config/endpoint/types/feature';
import themeVariables from '@/config/theme/themeVariable';
import { useInViewport, useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage, useReactCompareSliderRef } from 'react-compare-slider';

export default function Features() {
  const isMobile = useMediaQuery(themeVariables.layout.mobile);

  return (
    <div className='max-w-[1280px] pt-[100px] mx-auto sm:pt-[120px] pb-[40px]'>
      <div id={FeatureType.RemoveFurnitures} className='w-full flex gap-12 flex-col md:flex-row py-12 my-4 '>
        <CompareSlider
          imgSet={[{
            before: 'https://www.definition-lab.com/assets/feature1.png',
            after: 'https://raw.githubusercontent.com/nerdyman/stuff/main/libs/react-compare-slider/demo-images/lady-1.png'
          },{
            before: 'https://www.definition-lab.com/assets/feature1.png',
            after: 'https://www.designsense.ai/react-front/static/media/home_hero_slide-2.cac6a90caad74e480209.jpg'
          },{
            before: 'https://www.designsense.ai/react-front/static/media/home_hero_slide-1.e5acaf91eb9c15687afe.jpg',
            after: 'https://www.definition-lab.com/assets/feature1.png'
          }]}
          txtPosition='right'
          title='공간 정리하기'
          description='효율적이고 효과적인 근육 성장을 위해 근스터는 운동 전과 운동 후에 섭취해야하는 성분을 분리했어요\n효율적이고 효과적인 근육 성장을 위해 근스터는 운동 전과 운동 후에 섭취해야하는 성분을 분리했어요\n'
        />
      </div>

      <div id={FeatureType.InteriorRemodeling} className='w-full flex gap-12 flex-col md:flex-row py-12 my-4 '>
        <CompareSlider
          imgSet={[{
            before: 'https://www.definition-lab.com/assets/feature1.png',
            after: 'https://raw.githubusercontent.com/nerdyman/stuff/main/libs/react-compare-slider/demo-images/lady-1.png'
          },{
            before: 'https://www.definition-lab.com/assets/feature1.png',
            after: 'https://www.designsense.ai/react-front/static/media/home_hero_slide-2.cac6a90caad74e480209.jpg'
          },{
            before: 'https://www.designsense.ai/react-front/static/media/home_hero_slide-1.e5acaf91eb9c15687afe.jpg',
            after: 'https://www.definition-lab.com/assets/feature1.png'
          }]}
          txtPosition={isMobile ? 'right' :'left'}
          title='실내 인테리어 리모델링'
          description='효율적이고 효과적인 근육 성장을 위해 근스터는 운동 전과 운동 후에 섭취해야하는 성분을 분리했어요\n효율적이고 효과적인 근육 성장을 위해 근스터는 운동 전과 운동 후에 섭취해야하는 성분을 분리했어요\n'
        />
      </div>

      <div id={FeatureType.SketchToPictures} className='w-full flex gap-12 flex-col md:flex-row py-12 my-4 '>
        <CompareSlider
          imgSet={[{
            before: 'https://www.definition-lab.com/assets/feature1.png',
            after: 'https://raw.githubusercontent.com/nerdyman/stuff/main/libs/react-compare-slider/demo-images/lady-1.png'
          },{
            before: 'https://www.definition-lab.com/assets/feature1.png',
            after: 'https://www.designsense.ai/react-front/static/media/home_hero_slide-2.cac6a90caad74e480209.jpg'
          },{
            before: 'https://www.designsense.ai/react-front/static/media/home_hero_slide-1.e5acaf91eb9c15687afe.jpg',
            after: 'https://www.definition-lab.com/assets/feature1.png'
          }]}
          txtPosition='right'
          title='스케치 현실화'
          description='효율적이고 효과적인 근육 성장을 위해 근스터는 운동 전과 운동 후에 섭취해야하는 성분을 분리했어요\n효율적이고 효과적인 근육 성장을 위해 근스터는 운동 전과 운동 후에 섭취해야하는 성분을 분리했어요\n'
        />
      </div>

      <div id={FeatureType.FloorplanToPictures} className='w-full flex gap-12 flex-col md:flex-row py-12 my-4 '>
        <CompareSlider
          imgSet={[{
            before: 'https://www.definition-lab.com/assets/feature1.png',
            after: 'https://raw.githubusercontent.com/nerdyman/stuff/main/libs/react-compare-slider/demo-images/lady-1.png'
          },{
            before: 'https://www.definition-lab.com/assets/feature1.png',
            after: 'https://www.designsense.ai/react-front/static/media/home_hero_slide-2.cac6a90caad74e480209.jpg'
          },{
            before: 'https://www.designsense.ai/react-front/static/media/home_hero_slide-1.e5acaf91eb9c15687afe.jpg',
            after: 'https://www.definition-lab.com/assets/feature1.png'
          }]}
          txtPosition={isMobile ? 'right' :'left'}
          title='평면도 입체화'
          description='효율적이고 효과적인 근육 성장을 위해 근스터는 운동 전과 운동 후에 섭취해야하는 성분을 분리했어요\n효율적이고 효과적인 근육 성장을 위해 근스터는 운동 전과 운동 후에 섭취해야하는 성분을 분리했어요\n'
        />
      </div>

      <div id={FeatureType.ExteriorRemodeling} className='w-full flex gap-12 flex-col md:flex-row py-12 my-4 '>
        <CompareSlider
          imgSet={[{
            before: 'https://www.definition-lab.com/assets/feature1.png',
            after: 'https://raw.githubusercontent.com/nerdyman/stuff/main/libs/react-compare-slider/demo-images/lady-1.png'
          },{
            before: 'https://www.definition-lab.com/assets/feature1.png',
            after: 'https://www.designsense.ai/react-front/static/media/home_hero_slide-2.cac6a90caad74e480209.jpg'
          },{
            before: 'https://www.designsense.ai/react-front/static/media/home_hero_slide-1.e5acaf91eb9c15687afe.jpg',
            after: 'https://www.definition-lab.com/assets/feature1.png'
          }]}
          txtPosition='right'
          title='건물 외관 리모델링'
          description='효율적이고 효과적인 근육 성장을 위해 근스터는 운동 전과 운동 후에 섭취해야하는 성분을 분리했어요\n효율적이고 효과적인 근육 성장을 위해 근스터는 운동 전과 운동 후에 섭취해야하는 성분을 분리했어요\n'
        />
      </div>
    </div>
  )
}

const CompareSlider = ({ imgSet, txtPosition, title, description, animation }: { imgSet: any, txtPosition: 'left' | 'right', title: string, description: string, animation?: boolean }) => {
  const { ref, inViewport } = useInViewport();
  const [ animated, setAnimated ] = useState(false);
  const [ selectedImgIndex, setSelectedImgIndex ] = useState(0)

  const reactCompareSliderRef = useReactCompareSliderRef();

  useEffect(() => {
    if (!animation || animated) {
      return
    }
    const fireTransition = async () => {
      await new Promise(resolve => setTimeout(() => {
        reactCompareSliderRef?.current?.setPosition(65);
        resolve(true);
      }, 750));
      await new Promise(resolve => setTimeout(() => {
        reactCompareSliderRef?.current?.setPosition(35);
        resolve(true);
      }, 750));
      await new Promise(resolve => setTimeout(() => {
        reactCompareSliderRef?.current?.setPosition(50);
        resolve(true);
      }, 750));
    };

    fireTransition();
    setAnimated(true);
  }, [inViewport]);

  return (
    <>
      { txtPosition == 'left' &&
        <div className='mr-auto md:ml-4 py-4 flex flex-col gap-0 px-4 md:px-0'>
          <div className='text-gray-800 font-bold text-3xl'>{title}</div>
          <div className='text-gray-500 text-[15px] mt-5'>{description}</div>
          <div className='flex flex-row mt-auto gap-1 pt-4'>
            {imgSet.map((item: any, index: number) => (
              <div key={index}>
                <img
                  src={item?.after}
                  className='w-20 h-14 rounded-lg object-cover cursor-pointer'
                  onClick={() => setSelectedImgIndex(index)}
                />
              </div>
            ))}
          </div>
        </div>
      }
      <div ref={ref} className='flex items-center px-2 justify-center w-full rounded-2xl'>
        <ReactCompareSlider
          ref={reactCompareSliderRef}
          boundsPadding={0}
          transition="0.5s ease"
          className='md:h-[360px] md:w-[600px] rounded-2xl'
          itemOne={
            <div className='flex items-center w-full h-full justify-start'>
              <div className='absolute py-2 px-2.5 m-2 rounded-xl text-sm backdrop-blur-lg bg-black/40 text-white font-medium'>전</div>
              <ReactCompareSliderImage src={imgSet[selectedImgIndex].before} />
            </div>
          }
          itemTwo={
            <div className='flex items-center w-full h-full justify-end'>
              <div className='absolute py-2 px-2.5 m-2 rounded-xl text-sm backdrop-blur-lg bg-black/40 text-white font-medium'>후</div>
              <ReactCompareSliderImage src={imgSet[selectedImgIndex].after} />
            </div>
          }
        />
      </div>
      { txtPosition == 'right' &&
        <div className='ml-auto md:mr-4 py-4 flex flex-col gap-0 px-4 md:px-0'>
          <div className='text-gray-800 font-bold text-3xl'>{title}</div>
          <div className='text-gray-500 text-[15px] mt-5'>{description}</div>
          <div className='flex flex-row mt-auto gap-1 pt-4'>
            {imgSet.map((item: any, index: number) => (
              <div key={index}>
                <img
                  src={item?.after}
                  className='w-20 h-14 rounded-lg object-cover cursor-pointer'
                  onClick={() => setSelectedImgIndex(index)}
                />
              </div>
            ))}
          </div>
        </div>
      }
    </>
  )
}
