import { Grid } from "@mantine/core";

export default function Statistics() {
  return(
    <div className="max-w-[1280px] pt-[100px] mx-auto">
      <Grid gutter={0}>
        <Grid.Col span={{ base: 6, md: 6, lg: 3 }} className="flex justify-center">
          <div className="flex flex-col p-2 w-[160px] md:w-max" data-aos="fade" data-aos-delay="200" data-aos-duration="500">
            <h4 className="text-lg sm:text-xl font-semibold text-gray-700 dark:text-gray-200">더 많은 관심도</h4>
            <p className="mt-2 sm:mt-3 text-3xl sm:text-5xl font-bold text-gray-800">80%</p>
            <p className="mt-3 text-sm md:text-base text-gray-500 max-w-60">더 깔끔한 매물 사진을 보여줌으로써 매물 관심도가 올라가요</p>
          </div>
        </Grid.Col>
        <Grid.Col span={{ base: 6, md: 6, lg: 3 }} className="flex justify-center" data-aos="fade" data-aos-delay="400" data-aos-duration="500">
        <div className="flex flex-col p-2 w-[160px] md:w-max">
          <h4 className="text-lg sm:text-xl font-semibold text-gray-700 dark:text-gray-200">더 빠른 거래</h4>
          <p className="mt-2 sm:mt-3 text-3xl sm:text-5xl font-bold text-gray-800">70%</p>
          <p className="mt-3 text-sm md:text-base text-gray-500 max-w-60">더 높은 관심도를 통해 더 빠른 거래가 이루어져요</p>
        </div>
        </Grid.Col>
        <Grid.Col span={{ base: 6, md: 6, lg: 3 }} className="flex justify-center" data-aos="fade" data-aos-delay="600" data-aos-duration="500">
        <div className="flex flex-col p-2 w-[160px] md:w-max">
          <h4 className="text-lg sm:text-xl font-semibold text-gray-700 dark:text-gray-200">더 높은 가격</h4>
          <p className="mt-2 sm:mt-3 text-3xl sm:text-5xl font-bold text-gray-800">25%</p>
          <p className="mt-3 text-sm md:text-base text-gray-500 max-w-60">평균적으로 25% 높은 가격에 매물이 판매돼요</p>
        </div>
        </Grid.Col>
        <Grid.Col span={{ base: 6, md: 6, lg: 3 }} className="flex justify-center" data-aos="fade" data-aos-delay="800" data-aos-duration="500">
          <div className="flex flex-col p-2 w-[160px] md:w-max">
            <h4 className="text-lg sm:text-xl font-semibold text-gray-700 dark:text-gray-200">서비스 만족도</h4>
            <p className="mt-2 sm:mt-3 text-3xl sm:text-5xl font-bold text-gray-800">92%</p>
            <p className="mt-3 text-sm md:text-base text-gray-500 max-w-60">이미 수 많은 이용자님들께서 만족하며 사용중이에요</p>
          </div>
        </Grid.Col>
      </Grid>
    </div>
  )
}