export const FAQS = [
  { 
    question: '가상 인테리어가 무엇인가요?',
    answer: '저렴한 비용으로 공간을 더욱 매력적으로 보이게 하는 방법입니다. 과거에는 실제 가구를 들여와 배치했던 반면, 가상 인테리어는 컴퓨터를 사용해 빈 방 사진에 가구와 장식을 추가합니다. 3D 이미지를 생성하는 특수 소프트웨어를 통해 실물처럼 보이게 만들어, 구매자에게 집의 잠재력을 효과적으로 보여줄 수 있습니다.'
  },
  { 
    question: '가상 인테리어의 이점은 무엇인가요?',
    answer: '실제 가구를 구매하거나 배치할 필요가 없어 비용과 시간을 크게 절감할 수 있습니다. 전통적인 스테이징 방식에 비해 최대 97%까지 저렴할 수 있으며, 다양한 스타일의 가구를 자유롭게 선택할 수 있어 더욱 유연합니다. 또한, 준비 시간도 훨씬 짧아 빠르게 사진을 준비할 수 있습니다.'
  },
  { 
    question: '중개사로써 얻을 수 있는 이점이 무엇인가요?',
    answer: '유연한 스타일 적용: 다양한 가구 스타일을 자유롭게 선택할 수 있어, 다양한 타겟 고객층에게 어필할 수 있습니다. 이는 매물의 매력을 극대화하고 더 빠른 판매를 도울 수 있습니다.\n예를 들면, 어지럽혀있는 기존 매물을 보여주는 것보다는 집이 비워져있는 말끔한 상태 혹은 깔끔히 정리된 공간을 보여줌으로써 더 많은 고객들의 긍정적인 관심을 받을 수 있습니다.\n\n경쟁력 강화: 고품질의 가상 스테이징 이미지는 중개사의 전문성을 강조하며, 시장에서의 신뢰도를 높여줍니다. 이는 더 많은 고객을 유치하고 거래 성사율을 높이는 데 기여합니다.'
  },
  { 
    question: '인테리어 디자이너로써 얻을 수 있는 이점이 무엇인가요?',
    answer: '클라이언트와의 원활한 소통: 클라이언트에게 여러 가지 디자인 옵션을 신속하게 제시할 수 있어, 고객의 요구에 보다 정확하게 부응할 수 있습니다. 이는 고객 만족도를 높이고 계약 성사 가능성을 높일 수 있습니다.\n\n디자인 포트폴리오 확장: 실제 공간을 꾸미지 않고도 다양한 디자인을 시각화할 수 있어, 포트폴리오를 풍부하게 만들 수 있습니다.\n\n비용 효율성: 실제 가구나 재료를 구매하지 않고도 다양한 디자인을 시도할 수 있어, 프로젝트 비용을 절감할 수 있습니다. 이는 예산 내에서 최대한의 결과를 도출하는 데 도움이 됩니다.\n\n시간 절약: 가상 스테이징은 디자인 변경 사항을 빠르게 적용할 수 있어, 프로젝트 진행 속도를 높일 수 있습니다. 이는 더 많은 프로젝트를 동시에 관리하거나 긴급한 요구 사항에 신속하게 대응할 수 있게 합니다.\n\n다양한 실험: 물리적인 제약 없이 다양한 디자인 아이디어를 실험할 수 있어, 창의적적이고 혁신적인 디자인을 구현을 도와줄 수 있습니다.'
  },
  { 
    question: '가상 인테리어가 완료되기까지 얼마나 걸리나요?',
    answer: '이미지 처리와 가구 배치를 포함한 모든 과정이 수 초 만에 완료할 수 있습니다. 빠른 시간 내에 결과물을 받아볼 수 있어 시간 효율성이 뛰어납니다.'
  },
  { 
    question: 'AI는 어떻게 작동되나요?',
    answer: 'Dspace의 AI는 고급 머신 러닝 알고리즘을 사용하여 업로드된 이미지나 스케치를 분석합니다. 스타일, 색상, 방 유형에 대한 선호도를 기반으로 여러 디자인 옵션을 생성하며, 레이아웃, 가구 배치, 인테리어 디자인 원칙 등을 고려하여 현실적이고 아름다운 방 디자인을 만들어냅니다.'
  },
  { 
    question: '한번 무료로 사용해볼 수 있나요?',
    answer: '네 물론입니다. 현재 Dspace는 무료 플랜을 지원하고 있습니다. 다만 무료 플랜에서는 기능적인 제한이 있을 수 있다는 점 참고 부탁드립니다.'
  },
  { 
    question: '한달 내에 사용하지 못한 크레딧은 어떻게 되나요?',
    answer: '한달 내에 사용하지 못한 크레딧은 다음 달로 이월됩니다. 예를 들어 1월에 구독을 시작하셨다면, 1월 31일까지 사용하지 못한 크레딧은 2월로 이월되어 추가적으로 사용할 수 있습니다.'
  },
  { 
    question: '이용 중 구독을 자유롭게 취소할 수 있나요?',
    answer: '서비스가 마음에 들지 않거나 개인적인 이유 등으로 서비스 구독을 언제든지 자유롭게 취소하실 수 있습니다. 멤버쉽 구독 취소시 해당 달의 남아 있는 기간까지만 기능을 사용하실 수 있으며, 그 이후부터는 더 이상 금액이 청구되지 않습니다.'
  },
  { 
    question: '구독 중 멤버십 플랜을 변경할 수 있나요?',
    answer: '멤버십 플랜은 언제든지 변경하실 수 있습니다. 멤버쉽 플랜 변경 시 새로운 플랜으로 멤버쉽이 새롭게 시작 되며, 기존 플랜의 요금은 일할 계산하여 부분 환불된다는 점 알려드립니다.'
  },
  { 
    question: '위에는 없는 질문이 있는데 어디로 문의하면 될까요?',
    answer: '서비스와 관련하여 궁금하신 점이 있으시다면 언제든지 편하게 contact@definition-lab.com 으로 연락주세요. 빠르게 확인 후 답변드리도록 하겠습니다.'
  },
]
