import themeVariables from "@/config/theme/themeVariable";
import { MembershipKorean, MembershipPrice } from "@/utils/payment";
import { CheckIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { RequestConsultingModal } from "./requestConsultingModal";
import { useDisclosure } from "@mantine/hooks";

export default function Pricing() {
  const [ opened, { open, close, toggle }] = useDisclosure();

  return (
    <div id={'pricing'} className="pt-[100px] sm:pt-[120px] pb-[40px]">
      <div className='text-center uppercase font-semibold text-base text-gray-500'>내게 맞는 플랜을 자유롭게 선택해보세요</div>
      <div className='text-center text-2xl md:text-3xl font-bold text-gray-700 mb-16 mt-2'>플랜별 가격</div>
      <div className="flex justify-center gap-6 flex-wrap" data-aos="fade-up" data-aos-delay="250" data-aos-duration="300">

        <div className="flex flex-col border border-gray-200 text-center rounded-xl p-8 w-[90%] sm:w-[340px]">
          <h4 className="font-medium text-sm md:text-lg text-gray-800 dark:text-gray-200 tracking-tight">{MembershipKorean.plus}</h4>
          <div className="flex gap-2 mt-3 justify-center items-end">
            <div className=" font-bold text-3xl md:text-4xl text-gray-800">{MembershipPrice.plus}</div>
            <div className="font-semibold text-base md:text-xl text-gray-500">원 / 1달</div>
          </div>
          <p className="mt-2 text-sm text-gray-500">
            기능의 제한없이 마음껏 이용해보세요
          </p>

          <ul className="mt-7 space-y-2.5 text-sm">
            <li className="flex space-x-2">
              <CheckIcon className="w-4 h-4 text-gray-600 stroke-[2.4px]"/>
              <span className="text-gray-800 dark:text-gray-400">
                네이버 플레이스 리포트
              </span>
            </li>

            <li className="flex space-x-2">
              <CheckIcon className="w-4 h-4 text-gray-600 stroke-[2.4px]"/>
              <span className="text-gray-800 dark:text-gray-400">
                플레이스 대시보드
              </span>
            </li>

            <li className="flex space-x-2">
              <CheckIcon className="w-4 h-4 text-gray-600 stroke-[2.4px]"/>
              <span className="text-gray-800 dark:text-gray-400">
                포스터 템플릿 무제한
              </span>
            </li>

            <li className="flex space-x-2">
              <CheckIcon className="w-4 h-4 text-gray-600 stroke-[2.4px]"/>
              <span className="text-gray-800 dark:text-gray-400">
                커스텀 랭킹 키워드 3개
              </span>
            </li>
          </ul>

          <Link
            className="mt-7 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gray-50 text-gray-700 hover:bg-gray-100 disabled:opacity-50 active:scale-[98%] disabled:pointer-events-none" 
            href="/my-account/membership"
          >
            사용하기
          </Link>
        </div>

        <div className="flex flex-col border-2 border-gray-600 text-center shadow-xl rounded-xl p-8 w-[90%] sm:w-[340px] md:my-[-16px]">
          <p className="mb-3">
            <span className={`${themeVariables.badge.gray} !ring-0`}>
              인기
            </span>
          </p>
          <h4 className="font-medium text-sm md:text-lg text-gray-800 dark:text-gray-200 tracking-tight">{MembershipKorean.pro}</h4>
          <div className="flex gap-2 mt-3 justify-center items-end">
            <div className=" font-bold text-3xl md:text-4xl text-gray-800">{MembershipPrice.pro}</div>
            <div className="font-semibold text-base md:text-xl text-gray-500">원 / 1달</div>
          </div>
          <p className="mt-2 text-sm text-gray-500">
            보다 꼼꼼하고 체계적으로 관리해보세요
          </p>
          <ul className="mt-7 space-y-2.5 text-sm">
            <li className="flex space-x-2">
              <CheckIcon className="w-4 h-4 text-gray-600 stroke-[2.4px]"/>
              <span className="text-gray-800 dark:text-gray-400">
                플러스 플랜의 모든 기능
              </span>
            </li>

            <li className="flex space-x-2">
            <CheckIcon className="w-4 h-4 text-gray-600 stroke-[2.4px]"/>
              <span className="text-gray-800 dark:text-gray-400">
                커스텀 랭킹 키워드 무제한
              </span>
            </li>

            <li className="flex space-x-2">
            <CheckIcon className="w-4 h-4 text-gray-600 stroke-[2.4px]"/>
              <span className="text-gray-800 dark:text-gray-400">
                플레이스 랭킹 변동 카카오톡 알림
              </span>
            </li>
          </ul>

          <Link
            className="mt-7 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-700 active:scale-[98%] disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            href="/my-account/membership"
          >
            사용하기
          </Link>
        </div>
        

        <div className="flex flex-col border border-gray-200 text-center rounded-xl p-8 w-[90%] sm:w-[340px]">
          <h4 className="font-medium text-sm md:text-lg text-gray-800 dark:text-gray-200 tracking-tight">{MembershipKorean.prime}</h4>
          <div className="flex gap-2 mt-3 justify-center items-end">
            <div className="font-bold !text-[32px] md:text-4xl text-gray-800">별도 문의</div>
            {/* <div className="font-semibold text-base md:text-xl text-gray-500">원 / 1달</div> */}
          </div>
          <p className="mt-2 text-sm text-gray-500">
            전문가를 통해 맞춤형으로 관리해보세요
          </p>
          <ul className="mt-7 space-y-2.5 text-sm">
            <li className="flex space-x-2">
              <CheckIcon className="w-4 h-4 text-gray-600 stroke-[2.4px]"/>
              <span className="text-gray-800 dark:text-gray-400">
                프로 플랜의 모든 기능
              </span>
            </li>

            <li className="flex space-x-2">
              <CheckIcon className="w-4 h-4 flex-shrink-0 text-gray-600 stroke-[2.4px]"/>
              <div className="flex flex-col gap-2">
              <span className="text-gray-800 dark:text-gray-400 text-left">
                매달 2회 주기적으로 전문가와의
              </span>
              <span className="text-gray-800 dark:text-gray-400 text-left">
                플레이스 랭킹 모니터링 및 개선 관련 컨설팅
              </span>
              </div>
            </li>
          </ul>

          <div
            className="cursor-pointer mt-7 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gray-50 text-gray-700 hover:bg-gray-100 active:scale-[98%] disabled:opacity-50 disabled:pointer-events-none" 
            onClick={open}
          >
            문의하기
          </div>
        </div>
        <RequestConsultingModal opened={opened} close={close}/>
      </div>
    </div>
  )
};