export const REVIEWS = [
  { name: '김*준', content: 'CAD로 몇시간동안 했던게 한 3분만에 끝나다니.. 너무 좋네요' },
  { name: '다*디자인', content: '확실히 어지럽혀져 있는 방 사진보다는 연락이 더 많이 오는 것 같네요.' },
  { name: '라*공인중개사사무소', content: '정말 문화충격입니다... 덕분에 잘 쓰고 있습니다...' },
  { name: '푸*디자인', content: '단순 스케치를 고급 렌더링으로 변환해줘서 정말 유용하게 쓰고 있습니다.' },
  { name: '정*공인중개사사무소', content: '방 비우는 기능 유용하게 사용하고 있습니다. 감사합니다.' },
  { name: '김*호', content: '기능도 다양하고 퀄리티도 상당하네요. 진짜 사진인줄 알았네요 ㅎㅎ' },
  { name: '임*현', content: '인테리어 디자이너로써 정말 너무 애용하는 서비스입니다.' },
  { name: '에*디자인', content: '좋은 서비스 제공해주셔서 감사합니다!!!! 덕분에 시간을 더 효율적으로 쓸 수 있네요' },
  { name: '정*우', content: '지인 추천으로 한번 이용해봤는데 정말 너무 좋아요!' },
  { name: '정*인테리어', content: '디자인에서 막힐때 돌려보곤 하는데 정말 브레인스토밍하기에 최고입니다.' },
  { name: '이*공인중개사무소', content: '어지럽혀져있는 방사진 보여줄때보다 확실히 연락이 더 많이 오는 것 같아요' },
  { name: '강*준', content: '매우 만족합니다! 더 많은 기능 기대하도록 할게요~' },
  { name: '선*인테리어', content: 'CAD로 몇시간 걸리는 작업을 정말 빠르게 할 수 있어서 좋네요.' },
  { name: '8*공인중개사사무소', content: '아직 사용한지 얼마 안됐지만 정말 컬쳐쇼크네요...' },
  { name: '이*공인중개사사무소', content: '매물 보러오는 사람이 더 는것 같아요. 감사합니다.' },
]