import { useState } from 'react';
import Image, { ImageProps } from 'next/image';
import { BLUR_URL, images } from '@/utils/images';


const CustomImage = ({ ...props }: ImageProps) => {
  const { src, alt, ...etc } = props;

  const [err, setErr] = useState(false);

  return (
    <Image
      className='object-cover'
      src={err || !src ? images.errorImageIcon : src}
      onError={() => setErr(true)}
      placeholder="blur"
      blurDataURL={BLUR_URL}
      loading={'eager'}
      alt={alt || 'img'}
      {...etc}
    />
  );
};

export default CustomImage;
