import { Group, Stack, Text } from "@mantine/core"
import Marquee from "react-fast-marquee";
import { useMediaQuery } from "@mantine/hooks";
import themeVariables from "@/config/theme/themeVariable";
import { REVIEWS } from "../../consts/reviews";


export default function Testimonial() {
  const isMobile = useMediaQuery(themeVariables.layout.mobile)

  const ReviewCard = ({ name, comment }: { name: string, comment: string }) => (
    <Stack className="p-4 rounded-xl my-2 w-[270px] h-[140px] md:w-[320px] md:h-[160px] bg-white mx-2">
      <Group gap={4}>
        {/* <Avatar mr={8}/> */}
        <div className="text-sm md:text-base text-gray-700 font-semibold">
          {name}
        </div>
        <div className="text-sm md:text-base text-gray-700">
          님의 리뷰
        </div>
      </Group>
      <div className="text-sm md:text-base text-gray-600 line-clamp-3">
        {comment}
      </div>
    </Stack>
  )

  return (
    <div id={'reviews'} className='pt-[100px] sm:pt-[120px] pb-[40px]'>
      <div className='text-center text-base uppercase font-semibold text-gray-500'>이미 수많은 사장님들이 선택했어요</div>
      <div className='text-center text-2xl md:text-3xl font-bold text-gray-700 mb-10 mt-2'>서비스 후기</div>
      <div className='py-10 bg-gray-50'>
        <Marquee speed={80} gradient={!isMobile}>
          <Group gap={0}>
            {REVIEWS.slice(0,7).map((item, index) => 
              <ReviewCard key={index} name={item.name} comment={item.content} />
            )}
          </Group>
        </Marquee>
        <Marquee speed={80} gradient={!isMobile}>
          <Group gap={0} className="translate-x-20">
            {REVIEWS.slice(7,16).map((item, index) => 
              <ReviewCard key={index} name={item.name} comment={item.content} />
            )}
          </Group>
        </Marquee>
      </div>
    </div>
  )
}



