import { mixpanelTrackPageView } from '@/utils/mixpanel';
import { Button, InputBase, Modal } from '@mantine/core';
import Toast from '@/components/Toast';
import themeVariables from '@/config/theme/themeVariable';
import { API_ENDPOINT } from '@/config/endpoint/endpoint';
import { useEffect, useRef, useState } from 'react';
import { IMaskInput } from 'react-imask';


export const RequestConsultingModal = ({ opened, close }: { opened: boolean, close: () => void }) => {
  const contactRef = useRef<any>();
  const [ contactMethod, setContactMethod ] = useState<string>('phone');
  
  useEffect(() => {
    if (opened) {
      mixpanelTrackPageView('HomePage_ConsultingModal');
    }
  }, [])


  const handleSubmit = async () => {
    await API_ENDPOINT.inquery.sendServiceRequestInquery({
      method: contactMethod,
      number: contactRef.current.value,
    })
    Toast.success('접수가 완료되었어요');
    close();
  }

  return (
    <Modal
      opened={opened}
      onClose={close}
      size={'md'}
      centered
      padding={24}
      withCloseButton={false}
      overlayProps={{
        backgroundOpacity: 0.6,
        blur: 4,
      }}
      classNames={{ content: 'scrollbar-hide' }}
    >
      <div className="flex flex-col w-full m-auto gap-0">
        <div className="text-lg text-gray-800 font-semibold tracking-tight mb-1" data-autofocus>
          상담 신청
        </div>
        <div className="text-gray-600 text-sm mt-1">
          연락받으실 번호를 남겨주시면 최대한 빠르게 연락드릴게요
        </div>
        <div className='my-5'>
          <div className='text-gray-700 text-[13px] flex gap-1.5 mb-3'>
            <div
              className={`${contactMethod == 'phone' ? themeVariables.border.dark : themeVariables.border.light} !px-2.5 !py-1.5 !rounded-lg cursor-pointer`}
              onClick={() => setContactMethod('phone')}
            >
              전화로 연락받기
            </div>
            <div
              className={`${contactMethod == 'kakaotalk' ? themeVariables.border.dark : themeVariables.border.light} !px-2 !py-1.5 !rounded-lg cursor-pointer`}
              onClick={() => setContactMethod('kakaotalk')}
            >
              카카오톡으로 연락받기
            </div>
          </div>
          <InputBase
            placeholder="010-1234-5678"
            component={IMaskInput}
            mask="01000000000"
            inputRef={contactRef}
            classNames={themeVariables.input.filled}
          />
        </div>
        <Button onClick={handleSubmit} classNames={themeVariables.button.dark}>
          상담 신청하기
        </Button>
      </div>
    </Modal>
  )
}