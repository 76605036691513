import ky from 'ky';
import { MembershipType } from '@/utils/payment';
import { IMembership, IPaymentHistory } from './types/membership';

export const CLIENT_ENDPOINT = process.env.NEXT_PUBLIC_CLIENT_ENDPOINT || 'https://www.define-space.com'
export const SERVER_ENDPOINT = process.env.NEXT_PUBLIC_SERVER_ENDPOINT || 'https://api.define-space.com'
export const STATIC_ENDPOINT = process.env.NEXT_PUBLIC_STATIC_ENDPOINT || 'https://static.define-space.com'
export const S3_ENDPOINT = process.env.NEXT_PUBLIC_S3_ENDPOINT || 'https://define-space.s3.ap-northeast-2.amazonaws.com'

export const apiInstance = ky.create({
  prefixUrl: SERVER_ENDPOINT,
  credentials: 'include',
  timeout: 120000,
  retry: { limit: 2, delay: attemptCount => 2 ** attemptCount * 1000 },
  hooks: {
    afterResponse: [
			async (_request, _options, response) => {
        if (!response.ok) {
          const errMessage = await response.text()
          throw new Error(errMessage)
        }
			}
    ]
  },
})

export const API_ENDPOINT = {
  jobs: {
    getDetail: async (jobId: string) => {
      const result = await apiInstance.get(`jobs/detail/${jobId}`)
      const data = await result.json()
      return data as any
    },
    executeRequest: async (feature_type?: string, pre_image_id?: string) => {
      const result = await apiInstance.get(`jobs/execute/request?feature_type=${feature_type}&pre_image_id=${pre_image_id}`)
      const data = await result.json()
      return data as any
    },
    executeProcess: async (job_id: string, params: any) => {
      const json = { job_id, params }
      const result = await apiInstance.post(`jobs/execute/process`, { json })
      const data = await result.json()
      return data as any
    },
    getExecuteResult: async (job_id: string) => {
      const result = await apiInstance.get(`jobs/execute/result?job_id=${job_id}`)
      const data = await result.json()
      return data as any
    },
    getRecentItems: async () => {
      const result = await apiInstance.get(`jobs/recent-items`)
      const data = await result.json()
      return data as any
    },
    getRecentImgaes: async (feature_type: string) => {
      const result = await apiInstance.get(`jobs/recent-images?feature_type=${feature_type}`)
      const data = await result.json()
      return data as any
    },
    getTags: async () => {
      const result = await apiInstance.get(`jobs/tags`)
      const data = await result.json()
      return data as any
    },
    addTags: async (job_id: string, tag: string) => {
      const json = { job_id, tag }
      const result = await apiInstance.post(`jobs/tags`, { json })
      const data = await result.json()
      return data as any
    },
    deleteTags: async (id: string) => {
      const result = await apiInstance.delete(`jobs/tags/${id}`)
      const data = await result.json()
      return data as any
    },
    getFavoriteItems: async (feature_type: string, tags: string) => {
      const json = { feature_type, tags }
      const result = await apiInstance.post(`jobs/favorite-items`, { json })
      const data = await result.json()
      return data as any
    },
    changeFavoriteItems: async (job_id: string, is_favorite: boolean) => {
      const json = { job_id, is_favorite }
      const result = await apiInstance.put(`jobs/favorite-items`, { json })
      const data = await result.json()
      return data as any
    },
  },
  inquery: {
    sendServiceRequestInquery: async (data: Object) => await apiInstance.post(`inquery/service`, data),
    sendFeatureRequestInquery: async (content: string) => await apiInstance.post(`inquery/new-feature`, { json: { content }}),
    sendErrorReport: async (content: any) => await apiInstance.post(`inquery/error`, { json: { content }}),
  },
  account: {
    logout: async () => apiInstance.post(`account/logout`),
    checkUserUrl: `account/check`,
    checkUser: async () => {
      const result = await apiInstance.get(`account/check`, { retry: 0 })
      const data = await result.json() as any
      return data
    },
    registerUser: async (code: string, phone: number, newsletterGranted: boolean) => {
      const json = { code, phone, newsletter_granted: newsletterGranted }
      const result = await apiInstance.post(`account/register`, { json })
      const data = await result.json() as any
      return data
    },
    getUser: async () => {
      const result = await apiInstance.get(`account/get-user`)
      const data = await result.json() as any
      return data
    },
    updateNewsletterStatus: async (status: boolean) => {
      const json = { status }
      const result = await apiInstance.post(`account/newsletter`, { json })
      const data = await result.json() as any
      return data
    },
    updateKakaoTalkStatus: async (status: boolean) => {
      const json = { status }
      const result = await apiInstance.post(`account/kakaotalk`, { json })
      const data = await result.json() as any
      return data
    },
    withdraw: async () => {
      const result = await apiInstance.post(`account/withdrawal`)
      const data = await result.json() as any
      return data
    },
    sendVerificationCode: async (number: string) => {
      const json = { number }
      const result = await apiInstance.post(`account/verification-code/send`, { json, retry: 0 })
      const data = await result.json() as any
      return data
    },
    checkVerificationCode: async (number: string, code: string) => {
      const json = { number, code }
      const result = await apiInstance.post(`account/verification-code/check`, { json, retry: 0 })
      const data = await result.json() as any
      return data
    },
    getInvitationCode: async () => {
      const result = await apiInstance.get(`account/invitation-code`)
      const data = await result.json() as any
      return data
    }
  },
  membership: {
    getMembership: async (): Promise<IMembership> => {
      const result = await apiInstance.get(`membership`)
      const data = await result.json() as IMembership
      return data
    },
    getPaymentHistory: async (): Promise<IPaymentHistory> => {
      const result = await apiInstance.get(`membership/payment/history`)
      const data = await result.json() as IPaymentHistory
      return data
    },
    updateMembership: async (membership: MembershipType) => {
      const json = { membership }
      const result = await apiInstance.post(`membership/payment/change`, { json })
      const data = await result.json() as any
      return data
    },
    getCreditUsage: async () => {
      const result = await apiInstance.get(`membership/credit-usage`)
      const data = await result.json() as any
      return data as any
    },
    checkBillingKey: async () => {
      const result = await apiInstance.get(`membership/payment/check-billing-key`)
      const data = await result.json() as any
      return data
    },
    checkPromotionCode: async (code: string) => {
      const result = await apiInstance.get(`membership/payment/promotion?code=${code}`, { retry: 0 })
      const data = await result.json() as any
      return data
    },
    tossSuccessfulPaymentRedirectUrl: `${SERVER_ENDPOINT}/membership/payment/process/successful`,
    tossFailedPaymentRedirectUrl: `${SERVER_ENDPOINT}/membership/payment/process/failed`
  },
}