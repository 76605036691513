export enum FeatureType {
  RemoveFurnitures = 'remove-furnitures',
  InteriorRemodeling = 'interior-remodeling',
  SketchToPictures = 'sketch-to-pictures',
  FloorplanToPictures = 'floorplan-to-pictures',
  ExteriorRemodeling = 'exterior-remodeling',
}

export const FeatureTypeInKorean = {
  'remove-furnitures': '공간 정리하기',
  'interior-remodeling': '실내 인테리어 리모델링',
  'sketch-to-pictures': '스케치 현실화',
  'floorplan-to-pictures': '평면도 입체화',
  'exterior-remodeling': '건물 외관 리모델링'
}