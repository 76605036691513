'use client';

import CustomImage from "@/components/CustomImage";
import Marquee from "react-fast-marquee";


export default function Hero() {
  return (
    <>
      <div className="relative px-4 max-w-5xl mx-auto pt-20 sm:pt-24">
        <Header/>
      </div>
      <Imgaes/>
    </>
  )
}


const Header = () => (
  <div className="relative isolate z-0 mb-14">
    {/* <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
      <div className="relative left-[calc(50% - 30rem))] aspect-[1155/678] w-[120rem] -translate-x-1/2 rotate-[200deg] bg-gradient-to-tr from-[#92a8ff] to-[#ff80b5] opacity-10 sm:left-[calc(50% - 30rem))] sm:w-[150rem]" style={{ clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" }}></div>
    </div> */}
    <h1 className="text-slate-900 font-extrabold text-3xl md:text-5xl !leading-normal text-center dark:text-white">
      공간을
      <div
        className="inline-block px-2.5 py-1 ml-4 mr-2.5 rounded-2xl bg-gray-100 text-gray-950"
        data-aos="fade" data-aos-duration="750"
      >
        재정의</div>
      하다
    </h1>
    <p className="mt-6 text-base md:text-lg text-gray-600 text-center max-w-3xl mx-auto dark:text-slate-400">
      <code className="font-semibold text-gray-600">
        디스페이스
      </code>
      를 통해 생성된 사진들을 확인해보세요
    </p>
  </div>
)

const Imgaes = () => (
  <div data-aos="fade" data-aos-duration="500">
    <Marquee pauseOnHover autoFill speed={120}>
      <div className="flex h-fit">
        {[1,2,3,4,5].map((item, index) => 
          <div key={index} className={`relative overflow-hidden rounded-3xl w-[300px] h-[220px] md:w-[600px] md:h-[450px] mr-2`}>
            <CustomImage
              fill
              src={'https://www.designsense.ai/react-front/static/media/home_hero_slide-1.e5acaf91eb9c15687afe.jpg'}
              className="transition duration-500 object-cover"
              alt={'recent-image'}
            />
          </div>
        )}
      </div>
    </Marquee>
  </div>
)